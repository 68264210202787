.relatedProducts {
  padding: 50px 0;
}

.relatedProducts__title {
  text-align: center;
}

.relatedProducts__body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
