.addressForm {
  padding: 20px 40px 40px 40px;
}

.grid {
  padding-bottom: 50px !important;
}

.addressForm__buttons {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 10px;
}

.paymentForm__buttons {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;
}

.goBackButton,
.nextButton {
  width: 150px;
  margin: 0 30px;
  height: 45px;
  font-size: 16px;
  cursor: pointer;
}

.goBackButton {
  border: 1px solid var(--pink);
  background-color: white;
  color: var(--pink);
  transition: all 0.3s;
}

.goBackButton:hover {
  background-color: rgb(179, 67, 173, 0.3);
  color: white;
  border: 1px solid white;
  transition: all 0.3s;
}

.nextButton {
  background-color: var(--pink);
  color: white;
  border: 1px solid var(--pink);
  transition: all 0.3s;
}

.nextButton:hover {
  background-color: white;
  font-size: 18px;
  color: var(--pink);
  border: 1px solid var(--pink);
  transition: all 0.3s;
}

.checkboxDiv {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-left: -12px;
}
.radioText {
  color: var(--transparentBlack);
}

.paymentForm {
  padding: 20px 40px 0px 40px;
}

.elementConsumer {
  padding: 0 15px !important;
}

.paymentTitle {
  padding-left: 15px;
}

.divider {
  margin: 0 15px !important;
  margin-bottom: 30px !important;
}

.paymentForm__error {
  padding-top: 20px;
  color: red;
  font-size: 14px;
  text-align: center;
}

.review__codeText {
  margin-top: -8px !important;
}

@media (max-width: 600px) {
  .goBackButton,
  .nextButton {
    margin: 0 20px;
  }
}

@media (max-width: 450px) {
  .goBackButton {
    margin-left: 0;
    margin-right: 15px;
  }
  .nextButton {
    margin-left: 15px;
    margin-right: 0px;
  }
}

@media (max-width: 370px) {
  .goBackButton {
    margin-right: 10px;
  }
  .nextButton {
    margin-left: 10px;
  }
}

@media (max-width: 370px) {
  .goBackButton {
    font-size: 14px;
    margin-left: -15px;
  }
  .nextButton {
    font-size: 16px;
    margin-right: -15px;
  }
}
