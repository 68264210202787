h1,
h2,
h3,
h4 {
  font-family: "Dekko", cursive;
  font-weight: 300;
}

h1 {
  font-size: 75px;
}

h2 {
  font-size: 30px;
  color: var(--black);
}

p,
a,
button,
div,
input {
  font-family: "Roboto", sans-serif;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.app {
  height: 100%;
}

.pages__top {
  background-color: var(--lightblue);
  height: 68px;
  width: 100%;
}

.checkout {
  min-height: 100vh;
  background-color: #eaeded;
}

.bigButton {
  background-color: var(--pink);
  width: 200px;
  color: white;
  height: 55px;
  outline: none;
  border: 1px solid var(--pink);
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 1.5px;
  z-index: 5;
  transition: all 0.3s;
  flex-wrap: nowrap;
}

.bigButton:hover {
  background-color: transparent;
  color: var(--pink);
  transition: all 0.3s;
}

.bigButtonWhite {
  background-color: white;
  width: 200px;
  color: var(--pink);
  height: 55px;
  outline: none;
  border: 1px solid var(--pink);
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 1.5px;
  z-index: 5;
  transition: all 0.3s;
  flex-wrap: nowrap;
}

.bigButtonWhite:hover {
  background-color: var(--pink);
  color: white;
  transition: all 0.3s;
}

.react-confirm-alert-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.react-confirm-alert-body > h1 {
  font-size: 30px;
  padding-bottom: 10px;
}

.confirmText {
  text-align: center;
}

.MuiPopover-root {
  overflow: hidden !important;
}

@media (max-width: 1050px) {
  h1 {
    font-size: 65px;
  }
  h2 {
    font-size: 28px;
  }

  .bigButtonWhite,
  .bigButton {
    width: 170px;
    height: 50px;
    font-size: 17px;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 55px;
  }

  h2 {
    font-size: 25px;
  }

  .bigButton {
    width: 170px;
    height: 50px;
    font-size: 17px;
  }

  .bigButtonWhite {
    width: 170px;
    height: 50px;
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 45px;
  }
  h2 {
    font-size: 23px;
  }

  .bigButtonWhite,
  .bigButton {
    width: 140px;
    height: 50px;
    font-size: 16px;
  }

  .react-confirm-alert {
    width: 100% !important;
  }

  .react-confirm-alert-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90% !important;
    border-radius: 0 !important;
    margin-left: -10px !important;
  }
}

@media (max-width: 400px) {
  .bigButtonWhite,
  .bigButton {
    width: 120px;
    height: 45px;
    font-size: 16px;
  }
}
