.tabBoard {
  padding: 50px 0;
  border-bottom: 1px solid var(--lightblue);
  position: relative;
}

.tabBoard__tabs {
  display: flex;
  width: fit-content;
  border-right: 1px solid var(--lightblue);
  border-top: 1px solid var(--lightblue);
  border-left: 1px solid var(--lightblue);
  z-index: 2;
  position: relative;
  margin-left: 40px;
}

.tabBoard__tab {
  margin: 10px 20px 10px 20px;
  border-bottom: 1px solid var(--pink);
  cursor: pointer;
}

.tab1Off {
  border-bottom: 1px solid var(--lightblue);
}

.tab1On {
  border-bottom: 1px solid white;
}

.tab2Off {
  border-bottom: 1px solid var(--lightblue);
  border-left: 1px solid var(--lightblue);
}

.tab2On {
  border-left: 1px solid var(--lightblue);
  border-bottom: 1px solid white;
}

.tabBoard__text {
  padding: 50px 40px;
  border-top: 1px solid var(--lightblue);
  text-align: center;
  margin-top: -1px;
  z-index: -1;
  line-height: 1.5;
}

.tabBoard__img1 {
  position: absolute;
  bottom: -223px;
  left: -90px;
  transform: rotate(180deg);
  z-index: -1;
}

.tabBoard__img2 {
  position: absolute;
  bottom: -700px;
  right: -120px;
  z-index: -1;
}

@media (max-width: 1050px) {
  .tabBoard__img1 {
    height: 160px;

    bottom: -160px;
    left: -50px;
  }

  .tabBoard__img2 {
    bottom: -690px;
    right: -80px;
    height: 200px;
  }
}

@media (max-width: 1050px) {
  .tabBoard__img1 {
    height: 130px;
    bottom: -130px;
  }

  .tabBoard__img2 {
    height: 170px;
  }
}

@media (max-width: 500px) {
  .tabBoard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabBoard__tabs {
    margin-left: 0;
  }

  .tabBoard__img1 {
    height: 120px;
    bottom: -120px;
    left: -30px;
    opacity: 0.8;
  }

  .tabBoard__img2 {
    height: 150px;
    bottom: -635px;
    right: -20px;
    opacity: 0.8;
  }
}
