.checkoutForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.checkoutForm__paper {
  width: 90%;
  max-width: 800px;
  border-radius: 20px !important;
}

.checkoutForm__title {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;
  color: var(--transparentBlack);
}

.stepper {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.step {
  color: pink !important;
}

.addressForm {
  padding: 20px 40px 40px 40px;
}
