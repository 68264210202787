.footer {
  background-color: var(--black);
  color: lightgray;
  font-size: 12px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 10;
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer__right {
  display: flex;
}

.footer__link {
  text-decoration: underline;
  cursor: pointer;
  padding-left: 20px;
  color: inherit;
}

.footer__link:hover {
  color: rgb(173, 173, 173);
}
