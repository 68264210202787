html {
  scroll-behavior: smooth;
}

.carousel {
  margin-top: -70px;
  z-index: -1;
}

.home__button {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 490px;
}

.home__bottom {
  padding-top: 30px;
  margin-top: -30px;
  background: linear-gradient(310deg, rgba(49, 193, 212, 0.7), #ffffff);
  background-size: 400% 400%;
  background-position: ;
  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  -o-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 800px) {
  .home__button {
    top: 390px;
  }
}

@media (max-width: 800px) {
  .home__button {
    top: 390px;
  }
}

@media (max-width: 375px) {
  .home__button {
    top: 430px;
  }
}

@media (max-width: 304px) {
  .home__button {
    top: 440px;
  }
}
