.givingBackRight {
  padding: 0px 100px;
  position: relative;
}

.gbImgMain {
  height: 220px;
  width: 195px;
  object-fit: cover;
}

.gbImgSecondary {
  height: 200px;
  width: 180px;
  object-fit: cover;
}

.gbImg1 {
  transform: rotate(-8deg) translate(-35px, 0);
  position: relative;
}

.gbImg2 {
  transform: rotate(15deg) translate(15px, 0);
  position: relative;
}

.gbImg3 {
  transform: rotate(-8deg) translate(-35px, 0);
  position: relative;
}

@media (max-width: 1250px) {
  .givingBackRight {
    padding: 0px 50px;
  }
}

@media (max-width: 1050px) {
  .gbImgMain {
    height: 190px;
    width: 180px;
  }

  .gbImgSecondary {
    height: 170px;
    width: 160px;
  }
}

@media (max-width: 800px) {
  .givingBackRight {
    padding: 0 0 50px 0;
  }

  .gbImg1 {
    transform: rotate(-8deg) translate(30px, 10px);
  }

  .gbImg2 {
    transform: rotate(0deg);
  }

  .gbImg3 {
    transform: rotate(8deg) translate(-30px, 10px);
    z-index: -1;
  }
}

@media (max-width: 600px) {
  .givingBackRight {
    padding: 0 0 30px 0;
  }

  .gbImgMain {
    height: 165px;
    width: 160px;
  }
  .gbImgSecondary {
    height: 150px;
    width: 145px;
  }
}

@media (max-width: 450px) {
  .gbImg1 {
    position: absolute;
    transform: rotate(-8deg) translate(-80px, 0px);
  }

  .gbImg2 {
    transform: rotate(0deg);
    height: 160px;
    width: 155px;
  }

  .gbImg3 {
    position: absolute;
    transform: rotate(8deg) translate(-70px, 20px);
    z-index: -1;
  }
}

@media (max-width: 360px) {
  .gbImg1 {
    position: absolute;
    transform: rotate(-8deg) translate(-60px, 5px);
  }

  .gbImg2 {
    transform: rotate(0deg);
    height: 165px;
    width: 157px;
  }

  .gbImg3 {
    position: absolute;
    transform: rotate(8deg) translate(-90px, 25px);
    z-index: -1;
  }
}
