.cart {
  min-height: 100vh;
}
.cartContainer {
  background-color: #eaeded;
  height: fit-content;
  min-height: 93vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart__cart {
  background-color: white;
  width: 90%;
  max-width: 1200px;
  min-height: 50vh;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
}

.cart__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
}

.cart__title {
  border-bottom: 1.5px solid rgb(49, 193, 212, 0.6);
  color: var(--black);
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.cart__subtotal {
  background-color: rgb(49, 193, 212, 0.3);
  border-radius: 10px;
  padding: 25px 30px;
}

.cart__subtotalMb {
  display: none;
}

.cart__subtotalWhite {
  padding: 25px 50px;
}

.cart__subtotalTop {
  font-size: 18px;
}

.cart__button {
  background-color: rgb(179, 67, 173, 0.8);
  margin-top: 25px;
  width: 200px;
  color: white;
  height: 45px;
  outline: none;
  border: 1px solid var(--pink);
  cursor: pointer;
  letter-spacing: 1.5px;
  font-size: 18px;
  transition: all 0.3s;
}

.cart__button:hover {
  transition: all 0.3s;
  background-color: var(--pink);
}

@media (max-width: 1050px) {
  .cart__button {
    width: 170px;

    height: 45px;

    font-size: 17px;
  }

  .cart__subtotalTop {
    font-size: 17px;
  }

  .cart__subtotal {
    background-color: rgb(49, 193, 212, 0.3);
    border-radius: 10px;
    padding: 20px 30px;
  }
}

@media (max-width: 800px) {
  .cart__subtotalMb {
    display: flex;
    padding-bottom: 40px;
  }

  .cart__subtotal {
    display: none;
  }

  .cart__cart {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cart__button {
    margin-top: 10px;
    width: 200px;
    height: 50px;
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .cart__title {
    text-align: center;
  }
}
