* {
  margin: 0;
}

:root {
  --blue: #31c1d4;
  --lightblue: rgb(49, 193, 212, 0.8);
  --pink: #b343ad;
  --lightpink: rgb(179, 67, 173, 0.8);
  --taupe: rgb(244, 173, 69);
  --black: #20201f;
  --transparentBlack: rgb(31, 31, 30, 0.8);
}
