.counter {
  display: flex;
  align-items: center;
}

.counter__option {
  height: 30px;
  width: 30px;
  background-color: rgb(179, 67, 173, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
}

.counter__option:hover {
  background-color: var(--pink);
  transition: all 0.3s;
}

.counter__quantity {
  padding: 0 5px;
  color: var(--black);
}
