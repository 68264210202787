.product {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  cursor: pointer;
  width: 270px;
  padding-top: 20px;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

.product__img {
  height: 230px;
  width: 230px;
  object-fit: cover;
}

.product__bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: lightgray;
  height: 65px;
  width: 100%;
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.product__name {
  font-weight: 600;
  text-align: center;
}

.product__price {
  padding-top: 10px;
}

.product__buttonWrapper {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: all 0.3s;
}

.product__buttonWrapper:hover {
  opacity: 10;
  transition: all 0.3s;
}

.product__btnWrapperTouch {
  position: absolute;
  top: 0;
}

.product__button {
  margin-top: 170px;
  margin-bottom: 130px;
  margin-left: 50px;
  margin-right: 50px;
  background-color: rgb(179, 67, 173, 0.6);
  width: 215px;
  color: white;
  height: 55px;
  outline: none;
  border: 1px solid var(--pink);
  cursor: pointer;
  letter-spacing: 1.5px;
  font-size: 18px;
  transition: all 0.3s;
}

.product__buttonTouch {
  margin-top: 170px;
  margin-bottom: 130px;
  margin-left: 50px;
  margin-right: 50px;
  background-color: rgb(179, 67, 173, 0.6);
  width: 170px;
  color: white;
  height: 48px;
  outline: none;
  border: 1px solid var(--pink);
  cursor: pointer;
  letter-spacing: 1.5px;
  font-size: 18px;
  transition: all 0.3s;
}

.product__button:hover {
  transition: all 0.3s;
  background-color: var(--pink);
}

.product__counter {
  position: absolute;
  top: 10px;
  left: 10px;
}
