.teaItem {
  min-height: 90vh;
  position: relative;
  overflow: hidden;
}

.teaItem__container {
  padding: 80px 100px;
}

.teaItem__img1 {
  position: absolute;
  top: 210px;
  right: -70px;
  transform: rotate(270deg);
  z-index: -1;
}

@media (max-width: 1400px) {
  .teaItem__img1 {
    height: 170px;
  }
}

@media (max-width: 1050px) {
  .teaItem__container {
    padding: 80px 40px;
  }

  .teaItem__img1 {
    height: 140px;
  }
}

@media (max-width: 650px) {
  .teaItem__img1 {
    height: 120px;
    top: 60px;
    margin-right: 10px;
    opacity: 0.8;
  }
}

@media (max-width: 500px) {
  .teaItem__container {
    padding: 50px 0px;
  }
}

@media (max-width: 400px) {
  .teaItem__img1 {
    height: 100px;
    top: 70px;
    margin-right: 30px;
    opacity: 0.8;
  }
}
