.terms {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.terms__top {
  background-color: var(--blue);
  height: 65px;
  width: 100%;
}

.terms__content {
  padding: 0 40px 100px 40px;
  max-width: 1000px;
}

.terms__title {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.terms__bottomP {
  margin-top: 5em;
  font-size: 0.7em;
}
