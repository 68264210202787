.gbContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 70px 40px 140px 40px;
  position: relative;
  overflow: hidden;
  height: fit-content;
}

.gbLeaf {
  position: absolute;
  top: 550px;
  left: -90px;
  transform: scaleX(-1) rotate(0deg);
  opacity: 0.7;
  z-index: -1;
}

.gbSeeds {
  position: absolute;
  top: 760px;
  left: 50%;
  overflow: hidden;
  z-index: -1;
}

@media (max-width: 1250px) {
  .gbLeaf {
    top: 630px;
    left: -60px;
    height: 250px;
  }

  .gbSeeds {
    top: 740px;
    left: 45%;
    height: 150px;
  }
}

@media (max-width: 1050px) {
  .gbLeaf {
    height: 220px;
  }
}

@media (max-width: 990px) {
  .gbLeaf {
    top: 680px;
  }
  .gbSeeds {
    left: 55%;
    height: 130px;
    top: 790px;
  }
}

@media (max-width: 900px) {
  .gbLeaf {
    top: 740px;
  }
  .gbSeeds {
    left: 55%;
    height: 130px;
    top: 840px;
  }
}

@media (max-width: 850px) {
  .gbLeaf {
    top: 800px;
  }
  .gbSeeds {
    left: 55%;
    height: 130px;
    top: 900px;
  }
}

@media (max-width: 800px) {
  .gbContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    padding: 80px 0px;
  }

  .gbLeaf {
    top: 840px;
  }
  .gbSeeds {
    left: unset;
    right: 5%;
    top: 950px;
  }
}

@media (max-width: 600px) {
  .gbContainer {
    padding: 60px 0px;
  }
}

@media (max-width: 550px) {
  .gbLeaf {
    top: 960px;
    height: 150px;
    left: -40px;
  }

  .gbSeeds {
    top: 1010px;
    height: 100px;
  }
}

@media (max-width: 550px) {
  .gbLeaf {
    top: 1040px;
  }

  .gbSeeds {
    top: 1090px;
  }
}

@media (max-width: 400px) {
  .gbContainer {
    padding: 40px 0px;
  }

  .gbLeaf {
    top: 1120px;
  }

  .gbSeeds {
    top: 1180px;
  }
}

@media (max-width: 350px) {
  .gbLeaf {
    top: 1190px;
  }

  .gbSeeds {
    top: 1240px;
  }
}

@media (max-width: 330px) {
  .gbLeaf {
    top: 1250px;
  }

  .gbSeeds {
    top: 1300px;
  }
}

@media (max-width: 310px) {
  .gbLeaf {
    top: 1370px;
  }

  .gbSeeds {
    top: 1420px;
  }
}
