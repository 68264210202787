.swipableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  position: relative;
}

.innerMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  color: whitesmoke;
  transition: all 0.3s;
}

.MuiDrawer-paperAnchorTop {
  background-color: var(--blue) !important;
  overflow: hidden !important;
}

.swipableMenuItem {
  display: flex;
  align-items: center;
  font-size: 22px;
  margin: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--pink);
  transition: all 0.3s;
}

.swipableMenuItem > h3 {
  font-size: 18px;
}

.swipableMenuItem:hover {
  color: var(--pink);
  transition: all 0.3s;
}

.swipableOrange {
  position: absolute;
  height: 100px;
  top: -30px;
  left: -30px;
}

.swipableleaf {
  position: absolute;
  height: 130px;
  top: 120px;
  right: -30px;
}
