.teaInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  max-width: 1200px;
}

.teaInfo__img {
  height: 300px;
  width: auto;
  margin-left: -30px;
  z-index: -1;
}

.teaInfo__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.teaInfo__title {
  padding-bottom: 15px;
  font-size: 40px;
}

.teaInfo__price {
  padding-bottom: 15px;
}

.teaInfo__buttons {
  padding-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teaInfo__button1 {
  margin-right: 30px;
}

.teaInfo__buttonIcons {
  display: none !important;
}

@media (max-width: 1400px) {
  .teaInfo {
    padding: 0 70px;
  }

  .teaInfo__title {
    padding-bottom: 15px;
    font-size: 35px;
    text-align: center;
  }

  .teaInfo__price {
    padding-bottom: 15px;
  }

  .teaInfo__buttons {
    padding-top: 35px;
  }

  .teaInfo__button1 {
    margin-right: 30px;
  }
}

@media (max-width: 1250px) {
  .teaInfo__img {
    height: 250px;
    margin-left: -45px;
  }
}

@media (max-width: 950px) {
  .teaInfo {
    padding: 0px 40px;
  }
}

@media (max-width: 800px) {
  .teaInfo {
    flex-direction: column;
  }

  .teaInfo__right {
    align-items: center;
  }

  .teaInfo__title {
    font-size: 30px;
  }
  .teaInfo__img {
    height: 250px;
    margin-left: 0;
  }
  .teaInfo__counterPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
  }

  .teaInfo__price {
    padding: 0 10px 0 0;
  }
}

@media (max-width: 550px) {
  .teaInfo {
    padding: 0;
  }
  .teaInfo__title {
    font-size: 25px;
  }
  .teaInfo__img {
    height: 220px;
  }

  .teaInfo__button1 {
    margin-right: 20px;
  }

  .teaInfo__buttonIcons {
    display: unset !important;
  }

  .teaInfo__buttonTextPC {
    display: none;
  }
}

@media (max-width: 300px) {
  .teaInfo__button1 {
    margin-right: 15px;
  }
}
