.givingBackLeft {
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.gbTitle {
  border-bottom: 1px solid var(--pink);
  width: fit-content;
  padding: 0 5px;
  margin-bottom: 15px;
}

.gbText {
  padding: 15px 0;
  text-indent: 10px;
  z-index: 2;
  color: var(--black);
  line-height: 1.5;
}

.gbText > ul {
  padding: 15px 0;
  text-indent: 0;
}

.gbLink {
  cursor: pointer;
  color: var(--taupe);
}

@media (max-width: 1250px) {
  .givingBackLeft {
    padding: 0 50px;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1050px) {
  .givingBackLeft {
    padding: 0 50px 0 20px;
  }
}

@media (max-width: 800px) {
  .givingBackLeft {
    padding: 0 40px;
  }

  .gbTitleContainer {
    display: flex;
    justify-content: center;
  }
}
