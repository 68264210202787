.header {
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header__top {
  display: flex;
  align-items: center;
  background-color: white;
  color: var(--black);
  padding: 5px 40px;
  z-index: 1;
  height: 36px;
}

.header__charity {
  flex: 1;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

.header__charityMb {
  display: none;
}

.header__topRight {
  position: absolute;
  right: 10px;
}
.header__bottom,
.header__bottomColor {
  position: -webkit-sticky;
  top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96vw;
}

.header__bottom {
  background: linear-gradient(140deg, var(--transparentBlack), transparent 25%);
  transition: all 0.3s;
  position: absolute;
  top: 46px;
  padding: 4px 0 4px 10px;
}
.header__bottomColor {
  position: fixed;
  background-color: rgb(32, 32, 31, 0.35);
  top: 0;
  transition: all 0.3s;
  padding: 5px 40px 5px 10px;
}

.header__bottomLeft {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.logoMb {
  display: none;
  cursor: pointer;
}
.logo {
  height: 60px;
  width: auto;
  cursor: pointer;
}

.header__bottomRight {
  display: flex;
  align-items: center;
  color: white;
}

.header__option {
  padding-left: 20px;
  border-radius: 999px;
  cursor: pointer;
}

.header__option:hover {
  color: var(--pink);
}

#nav-icon {
  display: none;
}

@media (max-width: 1250px) {
  .header__bottom {
    background: linear-gradient(
      140deg,
      var(--transparentBlack),
      transparent 35%
    );
  }

  .header__charity {
    font-size: 16px;
  }
}
@media (max-width: 1050px) {
  .header__bottom,
  .header__bottomColor {
    width: 95vw;
  }

  .header__charity {
    font-size: 16px;
  }
}

@media (max-width: 910px) {
  .header__bottom {
    background: linear-gradient(
      140deg,
      var(--transparentBlack),
      transparent 40%
    );
  }
}

@media (max-width: 800px) {
  .header__p {
    display: none;
  }

  #nav-icon {
    z-index: 15;
    display: unset;
    width: 25px;
    height: 20px;
    position: relative;
    margin: 0 20px 0 25px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    background-color: white;
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 8px;
  }

  #nav-icon span:nth-child(4) {
    top: 16px;
  }

  #nav-icon.open span:nth-child(1) {
    top: 0px;
    width: 0%;
    left: 50%;
  }

  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg) translate(0px, 2px);
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg) translate(-7.5px, 5px);
  }

  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  .header__topRight {
    position: absolute;
    right: 0px;
  }

  .header__bottom,
  .header__bottomColor {
    width: 96vw;
  }
}

@media (max-width: 680px) {
  .header__bottom {
    background: linear-gradient(
      140deg,
      var(--transparentBlack),
      transparent 45%
    );
  }
  .header__bottom,
  .header__bottomColor {
    width: 95vw;
  }

  #nav-icon {
    margin: 0 10px 0 25px;
  }
}

@media (max-width: 600px) {
  .header__charity {
    display: none;
  }

  .header__charityMb {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
  }
}

@media (max-width: 530px) {
  .header__bottom,
  .header__bottomColor {
    width: 95vw;
  }
}

@media (max-width: 400px) {
  .logo {
    display: none;
  }
  .logoMb {
    display: unset;
    height: 60px;
  }
}
