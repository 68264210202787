.searchBar {
  position: relative;
}

.searchBar__wrapper0 {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 48px;
  right: 0;
  transition: all 0.5s;
  opacity: 0;
}

.searchBar__wrapper {
  opacity: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 48px;
  right: 0;
  transition: all 0.5s;
}

.searchBar__input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.searchBar__input > input {
  background-color: rgb(255, 255, 255, 0.9);
  height: 100%;
  border: none;
  outline: none;
  width: 180px;
  padding: 0 10px;
  color: var(--black);
  font-size: 16px;
}

.searchIcon {
  background-color: var(--lightpink);
  padding: 8px 10px !important;
  color: white !important;
  transition: all 0.3s;
}

/* .searchIcon:hover {
  background-color: var(--pink);
  transition: all 0.3s;
} */

.searchBar__results {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.searchResult {
  background-color: rgb(255, 255, 255, 0.9);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  border-top: 1px solid lightgrey;
  cursor: pointer;
  transition: all 0.3s;
}

.searchResult:hover {
  background-color: white;
  transition: all 0.3s;
}

@media (max-width: 800px) {
  .searchBar {
    position: unset;
  }
  .searchBar__wrapper0 {
    top: 68px;
    align-items: center;
    width: 100vw;
    right: unset;
    left: 0;
  }

  .searchBar__wrapper {
    top: 68px;
    width: 100vw;
    right: unset;
    left: 0;
  }

  .searchBar__input {
    align-items: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
  }

  .searchBar__input > input {
    background-color: rgb(255, 255, 255, 0.95);
    flex: 1;
    overflow: hidden;
    border-radius: 0;
  }

  .searchIcon {
    padding: 13px 13px !important;
  }

  .searchBar__results {
    width: 100%;
    overflow: hidden;
  }

  .searchResult {
    background-color: rgb(255, 255, 255, 0.95);
    width: 100%;
    overflow: hidden;
    height: 50px;
  }
}
