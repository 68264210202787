.aboutTop {
  height: 440px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  position: relative;
  overflow: hidden;
}

.aboutTopMainImg {
  height: 270px;
  width: 250px;
  object-fit: cover;
  border-radius: 3px;
  padding: 0 15px;
}

.aboutTopSecondaryImg {
  height: 230px;
  width: 210px;
  object-fit: cover;
  border-radius: 3px;
}

.aboutTopImg1 {
  transform: rotate(-8deg);
  z-index: 2;
}

.aboutTopImg2 {
  transform: rotate(5deg);
  z-index: 3;
}

.aboutTopImg3 {
  transform: rotate(-6deg);
  z-index: 2;
}

.aboutTopAbsImg {
  position: absolute;
}

.aboutLeaf {
  left: -30px;
  bottom: 50px;
  height: 220px;
  transform: scaleX(-1);
}

.aboutCinnamon {
  right: 20px;
  top: 80px;
  height: 200px;
  transform: rotate(100deg);
}

@media (max-width: 1250px) {
  .aboutCinnamon {
    right: 10px;
    height: 150px;
  }

  .aboutLeaf {
    left: -30px;
    height: 170px;
  }
}

@media (max-width: 1050px) {
  .aboutTopMainImg {
    height: 250px;
    width: 235px;
    padding: 0 15px;
  }

  .aboutTopSecondaryImg {
    height: 210px;
    width: 195px;
    object-fit: cover;
  }

  .aboutCinnamon {
    right: -20px;
    top: 80px;
    height: 130px;
    transform: rotate(100deg);
  }

  .aboutLeaf {
    left: -30px;
    bottom: 30px;
    height: 150px;
    transform: scaleX(-1);
  }
}

@media (max-width: 800px) {
  .aboutCinnamon {
    opacity: 0.8;
  }

  .aboutLeaf {
    opacity: 0.8;
  }

  .aboutTopImg1 {
    z-index: 2;
    height: 180px;
    width: 165px;
  }

  .aboutTopImg2 {
    height: 215px;
    width: 205px;
    padding: 0 10px;
  }

  .aboutTopImg3 {
    z-index: 2;
    height: 180px;
    width: 165px;
  }
}

@media (max-width: 680px) {
  .aboutTop {
    height: 400px;

    padding-top: 25px;
  }
  .aboutTopImg1 {
    position: absolute;
    transform: rotate(-8deg) translate(-150px);
  }

  .aboutTopImg2 {
    transform: rotate(0deg) translate(0px, 10px);
  }

  .aboutTopImg3 {
    position: absolute;
    transform: rotate(8deg) translate(150px);
  }
}

@media (max-width: 600px) {
  .aboutTop {
    height: 300px;

    padding-top: 50px;
  }
  .aboutTopImg1 {
    position: absolute;
    transform: rotate(-8deg) translate(-100px);
  }

  .aboutTopImg2 {
    height: 180px;
    width: 165px;
    transform: translate(0);
    z-index: 2;
  }

  .aboutTopImg3 {
    position: absolute;
    transform: rotate(8deg) translate(100px);
  }

  .aboutCinnamon {
    height: 90px;
  }

  .aboutLeaf {
    height: 100px;
  }
}

@media (max-width: 500px) {
  .aboutTop {
    height: 260px;

    padding-top: 50px;
  }
  .aboutTopImg1 {
    transform: rotate(-8deg) translate(-80px);
    height: 150px;
    width: 140px;
  }

  .aboutTopImg2 {
    height: 150px;
    width: 140px;
  }

  .aboutTopImg3 {
    transform: rotate(8deg) translate(80px);
    height: 150px;
    width: 140px;
  }

  .aboutCinnamon {
    top: 50px;
  }

  .aboutLeaf {
    height: 100px;
    bottom: 20px;
  }
}

@media (max-width: 360px) {
  .aboutTopImg1 {
    transform: rotate(-8deg) translate(-50px);
    height: 150px;
    width: 140px;
  }

  .aboutTopImg2 {
    height: 150px;
    width: 140px;
  }

  .aboutTopImg3 {
    transform: rotate(8deg) translate(50px);
    height: 150px;
    width: 140px;
  }

  .aboutCinnamon {
    height: 70px;
  }

  .aboutLeaf {
    height: 70px;
  }
}
