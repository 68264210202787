.confirmation > p {
  padding: 20px 0;
  color: var(--black);
}

.confirmation {
  text-align: center;
  padding-bottom: 40px;
  padding-top: 30px;
}

.confirmation > button {
  border: 1.5px solid var(--pink);
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  padding: 12px 25px;
  background-color: transparent;
  font-size: 16px;
  color: var(--pink);
}

.confirmation > button:hover {
  background-color: var(--pink);
  color: white;
  transition: all 0.3s;
}
