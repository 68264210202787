.cartProduct {
  color: var(--black);
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  border-bottom: 1.5px solid rgb(49, 193, 212, 0.6);
  transition: all 0.3s;
}

.cartProduct__image {
  height: 230px;
  width: 230px;
  object-fit: cover;
}

.cartProduct__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cartProduct__title {
  padding-bottom: 30px;
  font-size: 22px;
}

.cartProduct__quantityPrice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cartProduct__quantity {
  display: flex;
  align-items: center;
}

.cartProduct__delete {
  margin-left: 20px;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.cartProduct__delete:hover {
  color: rgb(75, 74, 74);
  transition: all 0.3s;
}

.cartProduct__priceTotal {
  padding-top: 10px;
  font-weight: 600;
  font-size: 18px;
}

@media (max-width: 650px) {
  .cartProduct__image {
    height: 200px;
    width: 200px;
  }

  .cartProduct {
    flex-direction: column;
  }

  .cartProduct__quantityPrice {
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
  }

  .cartProduct__quantity {
    padding-right: 35px;
  }

  .cartProduct__right {
    align-items: center;
  }

  .cartProduct__title {
    padding-bottom: 20px;
    font-size: 22px;
    margin-top: -15px;
    text-align: center;
  }
}
