.categories__options {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  flex-wrap: wrap;
}

.categories__options > h2 {
  cursor: pointer;
  color: var(--black);
  transition: all 0.3s;
  padding: 10px 40px;
}
.categories__optionOff {
  border-bottom: 3px solid white;
}

.categories__options > h2:hover {
  color: var(--pink);
  transition: all 0.3s;
}

.categories__optionOn {
  border-bottom: 3px solid var(--pink);
}

@media (max-width: 1050px) {
  .categories__options > h2 {
    padding: 5px 20px;
  }
}

@media (max-width: 800px) {
  .categories__options > h2 {
    padding: 5px 10px;
    margin: 0 20px;
  }

  .categories__options {
    margin-left: 10px;
    margin-right: 10px;
  }
}
