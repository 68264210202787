.CarouselItem {
  z-index: 5;
}

.item1,
.item2 {
  height: 740px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  overflow: hidden;
}

.item1 {
  background-color: var(--blue);
  text-align: center;
  align-items: center;
  position: relative;
  color: white;
}

.item2 {
  background-color: rgb(247, 190, 141);
  text-align: center;
  align-items: center;
  position: relative;
  color: rgb(92, 83, 73);
}

.item1 > img,
.item2 > img {
  position: absolute;
}

.item1__mint {
  left: -105px;
  top: 130px;
  height: 400px;
}

.item1__seeds {
  bottom: 20px;
  left: 20px;
}

.item1__petals {
  bottom: -0px;
  right: 50%;
}

.item1__cinamon {
  bottom: 0;
  right: 20%;
}

.item1__leaf {
  right: -20px;
}

.item1__orange {
  top: 120px;
  right: 18%;
}

.item1__flowers {
  top: 30px;
  right: 30%;
}

.item1__nut {
  top: 10px;
  left: 25%;
}

.peach1 {
  top: 60%;
  left: 0px;
  height: 180px;
}

.peachBranch1 {
  left: -10px;
  top: 20%;
  height: 200px;
  transform: scaleX(-1);
}

.peachFlower1 {
  bottom: 0;
  left: 23%;
  height: 150px;
}

.peach3 {
  bottom: -60px;
  right: 23%;
  height: 200px;
}

.peach2 {
  right: 0;
  top: 55%;
  height: 200px;
}

.peachFlower2 {
  right: -20px;
  top: 20%;
  height: 180px;
  transform: rotate(-90deg);
}

.peachBranch2 {
  height: 120px;
  top: 50px;
  right: 23%;
}

.peach3-2 {
  top: 0;
  left: 35%;
  height: 150px;
  transform: scaleY(-1) scaleX(-1);
}

.item__title {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 1.25;
  z-index: 10;
}

.item__description {
  font-size: 22px;
  padding-top: 20px;
  padding-bottom: 10px;
  z-index: 10;
  padding-left: 70px;
  padding-right: 70px;
}

@media (max-width: 1400px) {
  .item1__mint {
    top: 150px;
    height: 300px;
    left: -80px;
  }

  .item1__seeds {
    bottom: 20px;
    left: -20px;
    height: 150px;
  }

  .item1__petals {
    bottom: -0px;
    right: 55%;
    height: 170px;
  }

  .item1__cinamon {
    bottom: 0;
    right: 15%;
    height: 160px;
  }

  .item1__leaf {
    height: 270px;
  }

  .item1__orange {
    height: 120px;
  }

  .item1__flowers {
    top: 30px;
    right: 30%;
  }

  .item1__nut {
    top: 30px;
    left: 25%;
    height: 200px;
  }
}

@media (max-width: 1050px) {
  .item1__mint {
    left: -105px;
    top: 160px;
    height: 270px;
  }

  .item1__seeds {
    bottom: 110px;
    left: -30px;
    height: 120px;
  }

  .item1__petals {
    bottom: -0px;
    right: 60%;
    height: 150px;
  }

  .item1__cinamon {
    right: 10%;
    height: 130px;
  }

  .item1__leaf {
    right: -20px;
    height: 225px;
  }

  .item1__orange {
    top: 130px;
    right: 10%;
    height: 105px;
  }

  .item1__flowers {
    height: 200px;
    right: 25%;
  }

  .item1__nut {
    top: 35px;
    left: 25%;
    height: 170px;
  }

  .peachBranch1 {
    height: 150px;
  }

  .peach1 {
    height: 130px;
  }

  .peachFlower1 {
    height: 130px;
  }

  .peach3 {
    height: 150px;
  }

  .peach2 {
    height: 170px;
  }

  .peachFlower2 {
    height: 150px;
  }

  .peachBranch2 {
    height: 100px;
  }

  .peach3-2 {
    height: 130px;
  }
}

@media (max-width: 800px) {
  .item1,
  .item2 {
    height: 600px;
  }

  .item1__mint {
    left: -85px;
    top: 160px;
    height: 200px;
  }

  .item1__seeds {
    bottom: 110px;
    left: -30px;
    height: 100px;
  }

  .item1__petals {
    bottom: -0px;
    right: 50%;
    height: 110px;
  }

  .item1__cinamon {
    right: 5%;
    height: 100px;
  }

  .item1__leaf {
    right: -40px;
    height: 170px;
    top: 270px;
  }

  .item1__orange {
    top: 140px;
    right: -40px;
    height: 85px;
  }

  .item1__flowers {
    height: 150px;
    top: 50px;
    right: 20%;
    transform: scaleX(-1);
  }

  .item1__nut {
    top: 45px;
    left: 150px;
    height: 120px;
  }
  .peachBranch1 {
    height: 100px;
    top: 30%;
  }

  .peach1 {
    height: 100px;
  }

  .peachFlower1 {
    height: 100px;
  }

  .peach3 {
    height: 120px;
  }

  .peach2 {
    height: 130px;
    top: 65%;
  }

  .peachFlower2 {
    height: 120px;
    top: 30%;
  }

  .peachBranch2 {
    height: 70px;
    top: 60px;
    right: 17%;
  }

  .peach3-2 {
    height: 100px;
    top: 30px;
    left: 45%;
  }
}

@media (max-width: 600px) {
  .item__description {
    padding-top: 20px;
  }

  .item__title {
    padding-top: 0px;
  }
  .item1__mint {
    left: -45px;
    top: 160px;
    height: 120px;
  }

  .item1__seeds {
    bottom: 180px;
    left: -30px;
    height: 60px;
  }

  .item1__petals {
    bottom: -0px;
    right: unset;
    left: 10%;
    height: 80px;
  }

  .item1__cinamon {
    right: 10%;
    height: 60px;
  }

  .item1__leaf {
    right: -40px;
    height: 120px;
    top: 320px;
  }

  .item1__orange {
    top: 140px;
    right: -40px;
    height: 75px;
  }

  .item1__flowers {
    height: 100px;
    top: 70px;
    right: 24%;
    transform: scaleX(1) rotate(-20deg);
  }

  .item1__nut,
  .peach3-2 {
    display: none;
  }

  .peachBranch1 {
    left: -30px;
  }

  .peach1 {
    height: 100px;
    left: -60px;
  }

  .peachFlower1 {
    height: 80px;
    left: 12%;
  }

  .peach3 {
    height: 100px;
    right: 12%;
  }

  .peach2 {
    height: 100px;
    top: 62%;
  }

  .peachFlower2 {
    height: 100px;
    top: 30%;
  }

  .peachBranch2 {
    height: 70px;
    top: 60px;
    right: 17%;
  }

  .peach3-2 {
    height: 100px;
    top: 30px;
    left: 45%;
  }
}

@media (max-width: 460px) {
  .item1__flowers,
  .peachBranch2 {
    display: none;
  }

  .item1__mint {
    left: -50px;
  }

  .peachFlower2 {
    top: 25%;
  }

  .peachBranch1 {
    left: -50px;
  }
}

@media (max-width: 305px) {
  .item__description {
    padding-top: 20px;
    padding: 20px 25px 0 25px;
  }
}
