.aboutBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px;
}

.aboutBottomContainer {
  text-align: center;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutTitle {
  margin-bottom: 10px;
  border-bottom: 1px solid var(--pink);
  width: fit-content;
  padding: 0 10px;
}

.aboutText {
  padding: 30px 0;
  line-height: 1.5;
}

.aboutLink {
  cursor: pointer;
  color: var(--taupe);
}

@media (max-width: 400px) {
  .aboutBottom {
    padding: 30px 20px;
  }
}
