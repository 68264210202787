.mailchimp__formContainer {
  padding-bottom: 20px;
}

.mc__formLabel {
  padding-bottom: 10px;
  font-size: 14px;
}

.mc__form > div > input {
  outline: none;
  border: none;
  background-color: whitesmoke;
  padding: 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
}

.mc__form > div > button {
  outline: none;
  border: none;
  background-color: lightgray;
  padding: 5px 10px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}

.mc__sending {
  color: var(--taupe) !important;
}

.mc__error {
  color: var(--lightpink) !important;
}

.mc__success {
  color: var(--lightblue) !important;
}
